import React from "react";
import { Link } from 'gatsby'
import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';

 
const heroImage = ({ blok }) => (

    <div id={ blok.slug } className={blok.image_align 
        ? "w-10/12 lg:w-11/12 m-auto py-16 lg:flex lg:py-24 container" 
        : "w-10/12 lg:w-11/12 m-auto py-16 lg:flex lg:py-24 container flex-row-reverse"}>

        <img src={ blok.main_image.filename } className="lg:w-3/6 xl:m-0" alt="Hirst Yarns - Textile Agency logo"/>
        
        <div class="lg:w-3/6 text-left lg:m-auto lg:mx-16 xl:mx-24 text-center pt-7">
            <h1 className="pb-8">
                { blok.heading }
            </h1>
            
       
            <p className="pb-8">
                { 
                render(blok.main_text, {
                    nodeResolvers: {
                        [NODE_PARAGRAPH]: (children) => <p>{children}<br/></p>
                    }
                })
            }
            </p>
          
       

            <Link to={`/${ blok.link_url.cached_url }`} className="pb-8 hover:underline hover:text-blue-700 uppercase">
                { blok.link_text } 
            </Link>

        </div>
        
    
    </div>

)
 
export default heroImage


// flex text-center m-auto py-24 mx-28 