import * as React from "react"
 
const ContactForm = ({ blok }) => (
    <form
      class="med:pt-8 pb-16 width-txt xl:w-2/5 l:w-6/12 m-auto"
      method="post"
      action="https://getform.io/f/198cd8a3-81a3-49d8-8b2e-58028b923d94"
    >
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 font-BrandonGrotesque" for="grid-first-name">
            First Name
          </label>
          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            name="first-name"
            type="text"
            placeholder="First Name"
            required
          />
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 font-BrandonGrotesque" for="grid-last-name">
            Last Name
          </label>
          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 font-BrandonGrotesque"
            name="last-name"
            type="text"
            placeholder="Last Name"
            required
          />
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 font-BrandonGrotesque" for="grid-password">
            Subject
          </label>
          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 font-BrandonGrotesque"
            name="subject"
            type="textarea"
            placeholder="Subject"
            required
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 font-BrandonGrotesque" for="grid-password">
            Email
          </label>
          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 font-BrandonGrotesque"
            name="email"
            type="email"
            placeholder="Your Email"
            required
          />
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 font-BrandonGrotesque" for="grid-password">
            Your message
          </label>
          <textarea
            name="message-textarea"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 font-BrandonGrotesque"
            rows="3"
            placeholder="Your message..."
            required
          ></textarea>
        </div>
      </div>

      <div className="text-center">
          <button className="bg-cream w-1/4 border-black border-2 hover:bg-cream-800 py-2 px-4 rounded-full font-BrandonGrotesque font-bold">SEND</button>
      </div>

    </form>
)

export default ContactForm
