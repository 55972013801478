import React from "react"
import Slider from "react-slick"
import "./layout.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import FluidImage from "./fluidImage"

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />
};

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", right: "100px" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", left: "100px" }}
//       onClick={onClick}
//     />
//   );
// }


const Slideshow = ({ blok }) => (
   
      <div>
        <Slider {...settings}>
          
          {blok.slides.map(slide => (
              <div className="mx-auto" key={slide.id}>

                <div className="m-auto">
                    <FluidImage filename={ slide.image.filename } alt="Slideshow images for Hirst Yarns"/>
                </div>

                  <h1 className="text-center slide-heading">{ slide.slide_text }</h1>
                  
                  {slide.btn_text &&
                  <div className="text-center slide-btn">
                      <button className="bg-cream hover:bg-gray-400 py-2 px-4 rounded-full"> <Link to={`/${ slide.btn_link.cached_url }`}> { slide.btn_text } </Link> </button>
                  </div>
                  }

              </div>
              
          ))}
        
        </Slider>
      </div>

)

export default Slideshow


