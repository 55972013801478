import * as React from "react"
 
const Teaser = ({ blok }) => (
    <div>
      <h2>
          { blok.headline }
      </h2>
      <img src={ blok.image.filename } alt="Icon credentials Hirst Yarns"/>
   
    </div>
)
 
export default Teaser
