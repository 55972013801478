import SbEditable from 'storyblok-react'
import Teaser from './teaser'
import Slideshow from './slideshow'
import TextBox from './textBox'
import ImageBlocks from './imageBlocks'
import HeroImage from './heroImage'
import IconRow from './iconRow'
import ContactForm from './contactForm'
import React from "react"
 
const Components = {
  'teaser': Teaser,
  'slider': Slideshow,
  'text_box': TextBox,
  'img_blocks': ImageBlocks,
  'heroImage': HeroImage,
  'iconRow': IconRow,
  'contactForm': ContactForm
}
 
const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<SbEditable content={blok}><Component blok={blok} /></SbEditable>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}
 
export default DynamicComponent
