import React from "react"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const FluidImage = ({ filename }) => {
  const fluidProps = getFluidGatsbyImage(filename, {
  })

  return (
    <Img fluid={fluidProps} />
  )
}

export default FluidImage