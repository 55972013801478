import React from "react";
import { Link } from 'gatsby';
import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';
 
const TextBox = ({ blok }) => (
    <div className="text-center med:pt-8 pb-16 width-txt xl:w-2/5 m-auto">
        <h1 className="pb-8 py-8  leading-none">
            { blok.header }
        </h1>
        
        <p className="pb-8">
        { 
                render(blok.text, {
                    nodeResolvers: {
                        [NODE_PARAGRAPH]: (children) => <p>{children}<br/></p>
                    }
                })
            }
        </p>

        <Link to={`/${ blok.btn_link.cached_url }`} className="text-coolGray-800 hover:underline hover:text-blue-700">
             { blok.btn_text } 
        </Link>
        
    
    </div>
)
 
export default TextBox
