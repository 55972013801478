import React from "react";
 
const IconRow = ({ blok }) => (
    <div>
      <h2 className="text-center w-3/5 m-auto">{ blok.icon_heading }</h2>

        <div className="flex flex-row flex-wrap gap-8 sm:gap-12 md:gap-20 justify-center my-8 m-auto text-center mx-3">
            {blok.icon_block.map(icon => (
                    <div>
                      
                        <img src={ icon.icon_img.filename } width={ 50 } className="pb-4 mx-auto" alt="Yarn spinners"/>    
                        <p className="text-center">   
                         { icon.icon_text }
                      </p>
                    </div> 
              ))}

        </div>
    
    </div>
)
 
export default IconRow