import React from "react"
import { Link } from 'gatsby'
import FluidImage from "./fluidImage"
 
const ImageBlocks = ({ blok }) => (
    <div>
          <h1 className="text-center m-8">{ blok.img_blocks_title }</h1>
          <div className="justify-center md:flex md:flex-row md:gap-9 lg:gap-10 xl:gap-8">
            {blok.img_block.map(card => (
              <div>
                <Link to={`/${ card.image_url.cached_url }#${card.slug}`}>

                <div className="md:w-52 xl:w-72 w-10/12 m-auto">
                <FluidImage filename={card.image.filename} />
                </div>

                <h2 className="text-center p-4 md:pt-2"> { card.image_text } </h2>
                </Link>
              </div>
            ))}
          </div>
    </div>
)
 
export default ImageBlocks
